import { ref } from 'vue'
import { defineStore } from 'pinia'

import { listOnetimes, deleteOnetime, updateSubscriptions } from '@rechargeapps/storefront-client'
import type { Onetime, OnetimeListParams, UpdateOnetimeRequest } from '@rechargeapps/storefront-client'
import { useAuthStore } from './auth'
import { isAddon } from '@/utils/recharge'

export const useOnetimesStore = defineStore('recharge/onetimes', () => {
  const { useSession } = useAuthStore()

  const loading = ref(false)
  const onetimes = ref<Onetime[]>()

  async function loadOnetimes (query: OnetimeListParams = {}) {
    let results: Onetime[] = []
    let response = await listOnetimes(useSession(), query)
    results = results.concat(response.onetimes)
    while (response.next_cursor) {
      response = await listOnetimes(useSession(), { cursor: response.next_cursor, limit: query.limit })
      results = results.concat(response.onetimes)
    }
    return results
  }

  const load = async () => {
    loading.value = true
    onetimes.value = await loadOnetimes()
    loading.value = false
  }

  const updateAddons = async (date: string, address_id: number, updates: Partial<UpdateOnetimeRequest>) => {
    const addons = (await loadOnetimes({ address_id })).filter(isAddon).filter(i => i.next_charge_scheduled_at === date)
    const groups: Onetime[][] = []
    for (let i = 0; i < addons.length; i += 20) {
      groups.push(addons.slice(i, i + 20))
    }
    for (const group of groups) {
      // bulk update max is 20
      await updateSubscriptions(
        useSession(),
        address_id,
        group.map(i => ({ id: i.id, ...updates })),
        { allow_onetimes: true }
      )
    }
  }

  const cancelAddons = async (date: string, address_id: number) => {
    const addons = (await loadOnetimes({ address_id })).filter(isAddon).filter(i => i.next_charge_scheduled_at === date)
    for (const addon of addons) {
      await deleteOnetime(useSession(), addon.id)
    }
  }

  const useOnetimes = () => {
    if (!onetimes.value && !loading.value) {
      load()
    }
    return onetimes
  }

  const reset = () => {
    onetimes.value = undefined
    loading.value = false
  }

  return {
    loading,
    onetimes,
    loadOnetimes,
    useOnetimes,
    updateAddons,
    cancelAddons,
    reset
  }
})
